import React from "react";
import styled from "styled-components";
import NavLink from "./NavLink";
import Logo from "./Logo";
import AuthButton from "./AuthButton";
import MenuIcon from "./MenuIcon";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0 20px;
  height: 64px;
`;

const NavItemContainer = styled.div`
  display: flex;
`;

const NavItemContainerRight = styled.div`
  display: flex;
  margin-left: auto;
`;

const Navbar = () => {
  return (
    <Nav>
      <Logo />
      <NavItemContainer>
        <NavLink to="/buy">Buy</NavLink>
        <NavLink to="/sell">Sell</NavLink>{" "}
        {/* Update NavLink to point to SellPage */}
        <NavLink to="/about-us">About Us</NavLink>
      </NavItemContainer>
      <NavItemContainerRight>
        <NavLink to="/buy">Saved Homes</NavLink>
        <NavLink to="/dashboard">Dashboard</NavLink>{" "}
        {/* Optional: Update as per your requirement */}
      </NavItemContainerRight>
      <AuthButton />
      <MenuIcon />
    </Nav>
  );
};

export default Navbar;
