// DashboardContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

// Define the context's type
interface DashboardContextType {
  propertySelected: number | null;
  setPropertySelected: (property: number | null) => void;
  unreadMessageCount: number;
  setUnreadMessageCount: (count: number) => void;
}

// Create the context with a default value
const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined
);

// Create a custom hook to use the dashboard context
export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};

// Define the provider component's props type
interface DashboardProviderProps {
  children: ReactNode;
}

// Create the provider component
export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const [propertySelected, setPropertySelected] = useState<number | null>(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);

  useEffect(() => {
    // log propertySelected whenever it changes
    console.log("Property selected:", propertySelected);
  }, [propertySelected]);

  // The value that will be given to the context's provider
  const value = {
    propertySelected,
    setPropertySelected,
    unreadMessageCount,
    setUnreadMessageCount,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
