// src/api/homeApi.ts
import { FormData as FormInfo } from "../components/sellersForm/SellersForm";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const saveHomeInfo = async (homeData: FormInfo) => {
  try {
    const formData = new FormData();

    // Append files and other data to formData
    homeData.photos.forEach((photo) => formData.append("photos[]", photo));
    homeData.videos.forEach((video) => formData.append("videos[]", video));

    // Append other home data
    for (const [key, value] of Object.entries(homeData)) {
      if (key !== "photos" && key !== "videos") {
        formData.append(key, value as string | Blob);
      }
    }

    const response = await fetch(`${API_BASE_URL}?url=createHome`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving home information:", error);
  }
};

export const getHome = async (homeId: string) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getHome&homeId=${homeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching home details:", error);
  }
};
