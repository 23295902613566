import React, { createContext, useContext, useState, ReactNode } from "react";

interface SearchParams {
  city: string;
  zipCode: string;
  address: string;
}

interface SearchContextType {
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
}

interface SearchProviderProps {
  children: ReactNode;
}

const initialSearchParams: SearchParams = {
  city: "",
  zipCode: "",
  address: "",
};
const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParams>(initialSearchParams);

  return (
    <SearchContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
