import React from "react";

const DocumentsPage = () => {
  return (
    <div>
      <h2>Documents</h2>
      <p>This is the Status Board page content.</p>
    </div>
  );
};

export default DocumentsPage;
