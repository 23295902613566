import React, { useState } from "react";
import Card, { CardProps } from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // Import the Button component
import MockHomeList from "../../mocks/mockData/mockHomeList.json"; // Ensure the JSON file is updated
import { useDashboard } from "../../context/DashboardContext";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";

interface HomeCardProps extends CardProps {
  selected?: boolean;
}

const ScrollableContainer = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
  display: flex;
  border-radius: 12px; // Set border-radius to 10px
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  // Hide scrollbar for Chrome, Safari and Opera
  ::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const HomeCard = styled(Card)<HomeCardProps>`
  margin-bottom: 10px;
  display: flex;
  padding: 8px;
  min-height: 104px;
  align-items: flex-start;
  background-color: ${(props) => (props.selected ? "#eceff1" : "#fff")};
  border: 1px solid; // Add this line
  border-color: ${(props) =>
    props.selected ? "#B19EF6" : "#605F5F1A"}; // Add this line
`;

const ImageBox = styled(Box)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
`;

const DetailIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 12px;
  background-color: #f5f3fe;
  height: 22px;
  padding: 0 10px; // Add this line
  margin-horizontal: 8px;
  & .MuiSvgIcon-root {
    color: #5f4bb6;
    margin-right: 4px;
  }
  & .detail-text {
    color: #5f4bb6;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const PriceTypography = styled(Typography)`
  padding-top: 5px;
  font-weight: 800;
  color: #37474f;
  margin-top: 4px;
`;

const ActionButton = styled(Button)`
  border-radius: 8px; // Set border-radius to 8px
  width: 136px; // Set width to 136px
  height: 30px; // Set height to 30px
  &.MuiButton-contained {
    background-color: #7c4dff; // Purple color for the 'Buy' button
    color: white;
    &:hover {
      background-color: #5e35b1; // Slightly darker purple on hover
    }
  }
  &.MuiButton-outlined {
    border: 1px solid #7c4dff; // Purple border for the 'Sell' button
    color: #7c4dff; // Purple text color for the 'Sell' button
    &:hover {
      border: 1px solid #5e35b1; // Slightly darker purple border on hover
      color: #5e35b1; // Slightly darker purple text on hover
    }
  }
`;

const ButtonsContainer = styled(Box)`
  border-radius: 10px;
  min-height: 46px;
  width: 289px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c5cae9;
  margin-bottom: 16px;
  padding: 0 8px;
  box-sizing: border-box;
  margin-top: 16px; // Optionally add margin to the top for spacing
`;

const HomeColumn = () => {
  const { setPropertySelected } = useDashboard();
  const [selectedHome, setSelectedHome] = useState<number | null>(null);
  const [mockHomes, setMockHomes] = useState(MockHomeList);

  const handleSelectHome = (id: number) => {
    setSelectedHome(id);
    setPropertySelected(id);
  };

  return (
    <ScrollableContainer>
      <ButtonsContainer>
        <ActionButton variant="contained">Buy</ActionButton>
        <ActionButton variant="outlined">Sell</ActionButton>
      </ButtonsContainer>
      {mockHomes.map((home) => (
        <HomeCard
          key={home.id}
          selected={home.id === selectedHome}
          onClick={() => handleSelectHome(home.id)}
          elevation={0}
        >
          <ImageBox>
            <CardMedia
              component="img"
              image={home.imgUrl}
              alt={home.title}
              style={{ width: "100%", height: "100%" }}
            />
          </ImageBox>
          <CardInfo>
            <Typography variant="subtitle1" component="h2" sx={{ fontSize: 8 }}>
              {home.address}
            </Typography>
            <Box display="flex" alignItems="center" py={1}>
              <DetailIconWrapper>
                <KingBedOutlinedIcon fontSize="small" />
                <Typography
                  className="detail-text"
                  variant="body2"
                  sx={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {home.beds}
                </Typography>
              </DetailIconWrapper>
              <DetailIconWrapper>
                <BathtubOutlinedIcon fontSize="small" />
                <Typography
                  className="detail-text"
                  variant="body2"
                  sx={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {home.baths}
                </Typography>
              </DetailIconWrapper>
              <DetailIconWrapper>
                <CropOutlinedIcon fontSize="small" />
                <Typography
                  className="detail-text"
                  variant="body2"
                  sx={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {home.sqft} sq ft
                </Typography>
              </DetailIconWrapper>
            </Box>
            <PriceTypography
              sx={{ fontSize: 10, color: "black", fontWeight: "bold" }}
            >
              {home.price}
            </PriceTypography>
          </CardInfo>
        </HomeCard>
      ))}
    </ScrollableContainer>
  );
};

export default HomeColumn;
