// src/components/homeListContainer/HomeListContainer.tsx
import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const HomeCard = styled(Card)`
  margin-bottom: 20px;
`;

const Media = styled(CardMedia)`
  height: 140px;
  width: 140px; // Set width to make it a square
  object-fit: cover; // This will cover the area, cropping the image if necessary
`;

const HomeListContainer = ({ homes }: { homes: Array<any> }) => {
  const navigate = useNavigate();

  const handleCardClick = (homeId: string) => {
    navigate(`/view-home/${homeId}`);
  };
  return (
    <Box>
      {homes.map((home: any, index: any) => (
        <HomeCard key={index} onClick={() => handleCardClick(home.home_id)}>
          {/* Handle image located on local development ex. public/media/default_home1.jpg accessed from home.default_image */}
          <Media
            image={process.env.PUBLIC_URL + home.default_image}
            title="Home Image"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              ${Number(home.price).toLocaleString()}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Beds: {home.beds}, Baths: {home.baths}, Sqft:{" "}
              {home.square_footage}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {home.address}, {home.city}, {home.state} {home.zipcode}
            </Typography>
          </CardContent>
        </HomeCard>
      ))}
    </Box>
  );
};

export default HomeListContainer;
