import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components"; // Ensure you have styled-components installed

// Create a theme instance
const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#333",
          fontWeight: "bold",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#007882",
            // Override the child component style here
            "& .MuiTypography-root": {
              color: "#fff",
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#3b4144",
          fontWeight: "bold",
          fontSize: "15px",
          textTransform: "none",
        },
      },
    },
  },
});

const StyledLink = styled(Link)`
  text-decoration: none; // This removes the underline
  color: inherit; // This ensures the link color is inherited from the parent
  &:hover {
    text-decoration: none; // This ensures the underline doesn't appear on hover
  }
`;

const NavLink = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button>
        {/* Use the StyledLink here instead of the raw Link component */}
        <StyledLink to={to}>
          <Typography variant="button">{children}</Typography>
        </StyledLink>
      </Button>
    </ThemeProvider>
  );
};
export default NavLink;
