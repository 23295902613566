// src/components/Dashboard/DashboardContent/TaskPage.tsx
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EventNoteIcon from "@mui/icons-material/EventNote";
import styled, { css } from "styled-components";
import { SelectProps } from "@mui/material/Select";

type StyledSelectProps = {
  status: "completed" | "working" | "upcoming";
} & SelectProps; // Include all SelectProps

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.color || "#c5cae9"};
  border-radius: 15px 15px 0 0; /* Apply border-radius to top corners */
  .MuiTableCell-root:first-of-type {
    border-top-left-radius: 15px;
  }
  .MuiTableCell-root:last-of-type {
    border-top-right-radius: 15px;
  }
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  vertical-align: middle; /* Align the content of TableCell in the middle vertically */
`;

const StyledTableCellRow = styled(TableCell)`
  border-bottom: none;
  padding: 8px; /* adjust padding as needed */
  /* If your content is still not aligning horizontally, you might want to ensure that there's no child with width: 100% */
`;

const TaskDate = styled(Typography)`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const SubtaskRow = styled(TableRow)`
  background-color: #ffffff;
  display: flex;
  border: 1px solid #e0e0e0; // Apply a thin border
`;

const SubtaskLabel = styled(Typography)``;

const DownloadIcon = styled(CloudDownloadIcon)`
  margin-left: auto;
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  &.MuiSelect-outlined.MuiSelect-select {
    padding-top: 4px; // Reduced padding
    padding-bottom: 4px; // Reduced padding
    padding-left: 8px; // Keep left padding as is for the text
    padding-right: 24px; // Adjust padding to match the design, considering the dropdown arrow
    background-color: ${({ status }) =>
      getColor(status)}; // Use the passed status prop
    font-size: 0.875rem; // You might need to adjust the font size to fit the content within the smaller height

    /* You may also need to adjust the line-height if the text doesn't vertically align well */
    line-height: normal;
  }

  /* Adjust the icon size and position if necessary */
  & .MuiSvgIcon-root {
    right: 5px; // Adjust if needed for better alignment of the dropdown arrow
  }

  /* This targets the outline variant of the select input to ensure the border matches the adjusted height */
  &.MuiOutlinedInput-root {
    height: 21px;
  }
`;

const TaskIcon = styled(CloudDownloadIcon)`
  margin-right: 8px;
`;

const TableWrapper = styled.div`
  padding-bottom: 30px; // Adds 20px padding at the bottom of each table
`;

const statuses = [
  {
    label: "Accept Offer",
    description: "Your Task Upload Document is completed",
    date: "Sat 25th Oct.",
    status: "completed",
    subtasks: [
      {
        label: "Sign Offer Letter",
        status: "completed",
        date: "Sat 25th Oct.",
      },
      { label: "Send to Agent", status: "completed", date: "Sat 25th Oct." },
    ],
  },
  {
    label: "Schedule Inspection",
    description: "Schedule your home inspection",
    date: "Mon 27th Oct",
    status: "working",
    subtasks: [
      {
        label: "Choose Inspector",
        status: "completed",
        date: "Sat 25th Oct.",
        owner: "buyer",
      },
      { label: "Book Appointment", status: "pending" },
      { label: "Confirm Appointment", status: "pending" },
    ],
  },
  {
    label: "Loan Approval",
    description: "Get your loan approved",
    date: "Wed 29th Oct.",
    status: "upcoming",
    subtasks: [
      { label: "Submit Loan Application", status: "pending" },
      { label: "Provide Required Documents", status: "pending" },
    ],
  },
  {
    label: "Document Verification",
    description: "Verify your documents",
    date: "Sat 30th Oct.",
    status: "completed",
    subtasks: [
      {
        label: "Collect Financial Records",
        status: "completed",
        date: "Sat 25th Oct.",
      },
      {
        label: "Notarize Documents",
        status: "completed",
        date: "Sat 25th Oct.",
      },
      {
        label: "Upload Documents to Portal",
        status: "completed",
        date: "Sat 25th Oct.",
      },
    ],
  },
  {
    label: "Closing",
    description: "Close on your home",
    date: "Sun 31st Oct.",
    status: "upcoming",
    subtasks: [
      { label: "Final Walkthrough", status: "pending" },
      { label: "Confirm Closing Date", status: "pending" },
      { label: "Review Closing Documents", status: "pending" },
    ],
  },
];

const getColor = (status: string) => {
  switch (status) {
    case "completed":
      return "#57FF8C"; // Green
    case "working":
      return "#FF9E9C"; // Red
    case "upcoming":
      return "#FFC45D"; // Yellow
    default:
      return "#c5cae9"; // Default color
  }
};

const TaskPage: React.FC = () => {
  return (
    <div style={{ paddingRight: 20, paddingLeft: 20 }}>
      <TableContainer component={Paper} elevation={0} square>
        {statuses.map((task, index) => (
          <TableWrapper key={index}>
            {" "}
            {/* Wrap each table with TableWrapper */}
            <Table>
              <StyledTableHead color={getColor(task.status)}>
                <TableRow>
                  <StyledTableCell colSpan={3}>
                    <Typography variant="h6" color="white">
                      {task.label}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {task.subtasks.map((subtask, subIndex) => (
                  <SubtaskRow key={subIndex}>
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox checked={subtask.status === "completed"} />
                        <SubtaskLabel variant="body1">
                          {subtask.label}
                        </SubtaskLabel>
                        <IconButton size="small">
                          <TaskIcon />
                        </IconButton>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledSelect
                        status={
                          subtask.status as "completed" | "working" | "upcoming"
                        }
                        value={subtask.status}
                        variant="outlined"
                      >
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                      </StyledSelect>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TaskDate variant="body2">
                        <EventNoteIcon fontSize="small" />
                        {subtask.date}
                      </TaskDate>
                    </StyledTableCell>
                  </SubtaskRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ))}
      </TableContainer>
    </div>
  );
};

export default TaskPage;
