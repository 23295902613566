// src/components/dashboard/MessageColumn.tsx
import React from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@material-ui/core";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: white;
  margin-top: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
`;

const MessageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MessageDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.span`
  font-weight: bold;
`;

const MessageText = styled.span`
  color: grey;
`;

const MessageColumn = () => {
  const mockMessages = [
    { username: "User1", time: "10:00 AM", message: "Hello, how are you?" },
    { username: "User2", time: "10:15 AM", message: "Can you help me with..." },
    { username: "User3", time: "11:00 AM", message: "I loved your last post!" },
    {
      username: "User4",
      time: "12:45 PM",
      message: "What do you think about...",
    },
    {
      username: "User5",
      time: "01:30 PM",
      message: "I have a question regarding...",
    },
  ];

  return (
    <MessageContainer>
      <Typography variant="h6">Connections</Typography>
      {mockMessages.map((message, index) => (
        <MessageItem key={index}>
          <Avatar>{message.username[0]}</Avatar>
          <MessageDetails>
            <UserName>{message.username}</UserName>
            <span>{message.time}</span>
            <MessageText>{message.message.split(". ")[0]}</MessageText>
          </MessageDetails>
        </MessageItem>
      ))}
    </MessageContainer>
  );
};

export default MessageColumn;
