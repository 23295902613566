// src/components/Dashboard/DashboardContent/ChatPage.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMessagesForUser } from "../../../api/messageApi";

interface Message {
  id: string;
  sender: string;
  content: string;
  timestamp: string;
}

// Mock data
const mockMessages: Message[] = [
  {
    id: "m1",
    sender: "Connection A",
    content:
      "Prototype comfort design in their company pipeline could be used.",
    timestamp: "Last seen, 2.02pm",
  },
  {
    id: "m2",
    sender: "User",
    content: "More exciting than just a new shoe design? How can",
    timestamp: "2.03pm",
  },
  {
    id: "m3",
    sender: "Connection A",
    content: "A variation of the Air Force!",
    timestamp: "2.04pm",
  },
  // Add more messages as needed
];
const ChatContainer = styled.div`
  padding: 20px;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageBubble = styled.div<{ isUser: boolean }>`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#f4f4f8" : "#e0e0e0")};
`;

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Sender = styled.span`
  font-weight: bold;
`;

const Timestamp = styled.span`
  font-size: 0.8rem;
`;

const MessageContent = styled.p`
  margin: 0;
`;

const MessageInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MessageInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  border-radius: 22px;

  &::placeholder {
    color: #c4c4c4;
  }
`;

const SendButton = styled.button`
  background-color: #7e57c2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6a45ae;
  }
`;

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>(mockMessages);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const userId = 1; // This should come from your user context or authentication state
    getMessagesForUser(userId)
      .then((data: any) => {
        setMessages(data.messages);
        setLoading(false);
        console.log(data.messages);
      })
      .catch((err: any) => {
        console.log(err.message);
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const handleSendMessage = () => {
    // Logic to send message
    console.log(newMessage);
    setNewMessage(""); // Clear the input field after sending a message
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ChatContainer>
      <h2>Chat</h2>
      <MessageList>
        {messages.map((message) => (
          <MessageBubble key={message.id} isUser={message.sender === "User"}>
            <MessageInfo>
              <Sender>
                {message.sender === "User" ? "You" : message.sender}
              </Sender>
              <Timestamp>{message.timestamp}</Timestamp>
            </MessageInfo>
            <MessageContent>{message.content}</MessageContent>
          </MessageBubble>
        ))}
      </MessageList>
      <MessageInputContainer>
        <MessageInput
          type="text"
          placeholder="Type something..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <SendButton onClick={handleSendMessage}>Send</SendButton>
      </MessageInputContainer>
    </ChatContainer>
  );
};

export default ChatPage;
