// src/components/Dashboard/DashboardContent/DashboardHeader.tsx
import React, { useState } from "react";
import styled, { css } from "styled-components";
import StatusBoardPage from "./StatusBoardPage";
import ChatPage from "./ChatPage";
import TasksPage from "./TasksPage";
import DocumentsPage from "./DocumentsPage";
import CalendarPage from "./CalendarPage";
import MainPage from "./MainPage";

interface PageTitleProps {
  isActive: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  position: relative; // Needed to position the divider line
  background-color: transparent; // Change this line
  z-index: 1;
`;

const PageTitle = styled.span<PageTitleProps>`
  cursor: pointer;
  margin-top: 20px;
  flex: 1; // Add this line
  text-align: center; // Add this line
  color: #191919;
  font-weight: bold;
  position: relative; // Needed for absolute positioning of the highlight
  ${(props) =>
    props.isActive &&
    css`
      color: #7c4dff; // Your highlight color
      font-weight: bold;

      &::after {
        content: "";
        position: absolute;
        bottom: -15px; // Adjust as needed based on your layout
        left: 0;
        right: 0;
        height: 2px; // Height of the highlight line
        background-color: #7c4dff; // Your highlight color
      }
    `}
`;

const DividerLine = styled.hr`
  position: absolute;
  bottom: -23px; // Adjust this line
  left: 0;
  right: 0;
  border: none;
  height: 2px;
  background-color: #e0e0e0; // Color for the divider line
  z-index: -1; // Add this line
`;
const DashboardHeader = () => {
  const [activePage, setActivePage] = useState("Chat");

  const renderPage = () => {
    switch (activePage) {
      case "Main":
        return <MainPage />;
      case "Chat":
        return <ChatPage />;
      case "Tasks":
        return <TasksPage />;
      case "Documents":
        return <DocumentsPage />;
      case "Calendar":
        return <CalendarPage />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{ backgroundColor: "white", borderRadius: 12 }}
      data-testid="headerWrapper"
    >
      <HeaderContainer>
        {["Chat", "Tasks", "Documents", "Calendar"].map((title) => (
          <PageTitle
            key={title}
            onClick={() => setActivePage(title)}
            isActive={activePage === title}
          >
            {title}
          </PageTitle>
        ))}
        <DividerLine />
      </HeaderContainer>
      <div style={{ padding: "10px 0px" }} />
      {renderPage()}
    </div>
  );
};

export default DashboardHeader;
