// src/api/messageApi.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getMessagesForUser = async (userId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getMessages&userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during fetching messages:", error);
    throw error;
  }
};
