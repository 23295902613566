// src/api/userApi.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const registerUser = async (email: string, password: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during user registration:", error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string) => {
  const response = await fetch(`${API_BASE_URL}?url=login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  return data;
};

export const testApiConnection = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=test`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during API test:", error);
    throw error;
  }
};
