// components/sellersForm/SellersForm.tsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { saveHomeInfo } from "../../api"; // Adjust the path as necessary
import styled from "styled-components";

const StyledCard = styled(Card)`
  display: flex;
  margin-left: 80px; // Added left margin of 80px
  margin-right: 80px; // Added right margin of 80px
  max-width: 1200px; // Prevents the card from stretching across the entire page
  margin-top: 20px;
  padding: 0 80px; // Added horizontal padding of 80px
`;

const CategoryList = styled.div`
  flex: 1;
  padding: 20px;
`;

const FormFields = styled.div`
  flex: 2;
  padding: 20px;
  position: relative; // Required for absolute positioning of children
  min-height: 400px; // Adjust according to your needs
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px; // Adjust this as needed
  left: 0;
  right: 0;
  padding: 0 20px; // Padding on both sides
`;

const categories = [
  "Home Location",
  "Contact",
  "Details",
  "Layout",
  "Features",
  "Photos",
  "Video",
  "Description",
  "Asking Price",
  "Post Your Home",
];

export interface FormData {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  floors: string;
  basement: boolean;
  garage: boolean;
  garageSpots: string;
  bathrooms: string;
  squareFootage: string;
  lotSize: string;
  pool: boolean;
  shed: boolean;
  highCeilings: boolean;
  mudRoom: boolean;
  fireplace: boolean;
  hardwoodFloors: boolean;
  centralAir: boolean;
  solarPanels: boolean;
  hoa: boolean;
  hoaFees: string;
  clubHouse: boolean;
  yearBuilt: string;
  roofAge: string;
  homeExterior: string;
  garden: boolean;
  securitySystem: boolean;
  updatedKitchen: boolean;
  photos: Array<File>;
  videos: Array<File>;
  description: string;
  askingPrice: string;
}

const SellersForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    floors: "",
    basement: false,
    garage: false,
    garageSpots: "",
    bathrooms: "",
    squareFootage: "",
    lotSize: "",
    pool: false,
    shed: false,
    highCeilings: false,
    mudRoom: false,
    fireplace: false,
    hardwoodFloors: false,
    centralAir: false,
    solarPanels: false,
    hoa: false,
    hoaFees: "",
    clubHouse: false,
    yearBuilt: "",
    roofAge: "",
    homeExterior: "",
    garden: false,
    securitySystem: false,
    updatedKitchen: false,
    photos: [],
    videos: [],
    description: "",
    askingPrice: "",
  });
  const handleNext = () => {
    if (activeStep < categories.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // Add validation and submission logic here
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name as keyof typeof formData;
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    console.log({ formData });
    try {
      const response = await saveHomeInfo(formData);
      if (response && response.success) {
        // Handle successful submission (e.g., display a success message, redirect, etc.)
        console.log("Form submitted successfully");
      } else {
        // Handle errors (e.g., display error message)
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const renderAskingPriceFields = () => (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Set Your Asking Price
      </Typography>
      <TextField
        label="Asking Price"
        name="askingPrice"
        variant="outlined"
        value={formData.askingPrice}
        onChange={(e) => {
          // Only allow numbers to be entered
          if (/^\d*$/.test(e.target.value)) {
            setFormData({ ...formData, askingPrice: e.target.value });
          }
        }}
        margin="normal"
        fullWidth
        helperText="Enter the price in USD. Only numbers are allowed."
      />
    </>
  );

  const renderDescriptionFields = () => (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Describe Your Home
      </Typography>
      <TextField
        label="Home Description"
        name="description"
        variant="outlined"
        value={formData.description}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
        multiline
        rows={4}
        helperText="Include details like the condition of your home, recent upgrades, and what you love about your home."
      />
    </>
  );

  const renderVideoFields = () => (
    <>
      <input
        accept="video/*"
        style={{ display: "none" }}
        id="video-upload-button-file"
        multiple
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            const videoArray = Array.from(e.target.files);
            setFormData({
              ...formData,
              videos: [...formData.videos, ...videoArray],
            });
          }
        }}
      />
      <label htmlFor="video-upload-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload Videos
        </Button>
      </label>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {formData.videos.map((file, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            <VideoLibraryIcon style={{ fontSize: 50 }} />
            <Typography variant="caption">{file.name}</Typography>
            <Button
              size="small"
              onClick={() => {
                const newVideosArray = formData.videos.filter(
                  (_, i) => i !== index
                );
                setFormData({ ...formData, videos: newVideosArray });
              }}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  const renderPhotosFields = () => (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            const fileArray = Array.from(e.target.files);
            setFormData({
              ...formData,
              photos: [...formData.photos, ...fileArray],
            });
          }
        }}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload Photos
        </Button>
      </label>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {formData.photos.map((file, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              width: "100px",
              textAlign: "center",
            }}
          >
            <img
              src={URL.createObjectURL(file)}
              style={{ width: "100%", height: "auto" }}
              alt={`Preview ${index}`}
            />
            <Typography variant="caption">{file.name}</Typography>
            <Button
              size="small"
              style={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => {
                const newPhotosArray = formData.photos.filter(
                  (_, i) => i !== index
                );
                setFormData({ ...formData, photos: newPhotosArray });
              }}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  const renderDetailsFields = () => (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.hoa}
            onChange={handleCheckboxChange}
            name="hoa"
          />
        }
        label="HOA"
      />

      {formData.hoa && (
        <TextField
          label="HOA Fees"
          name="hoaFees"
          variant="outlined"
          value={formData.hoaFees}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.clubHouse}
            onChange={handleCheckboxChange}
            name="clubHouse"
          />
        }
        label="Club House"
      />

      <TextField
        label="Year Built"
        name="yearBuilt"
        variant="outlined"
        value={formData.yearBuilt}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />

      <TextField
        label="Roof Age"
        name="roofAge"
        variant="outlined"
        value={formData.roofAge}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Home Exterior</InputLabel>
        <Select
          name="homeExterior"
          value={formData.homeExterior}
          onChange={handleSelectChange}
        >
          <MenuItem value="brick">Brick</MenuItem>
          <MenuItem value="wood">Wood</MenuItem>
          <MenuItem value="vinyl">Vinyl</MenuItem>
          <MenuItem value="stone">Stone</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.garden}
            onChange={handleCheckboxChange}
            name="garden"
          />
        }
        label="Garden"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.securitySystem}
            onChange={handleCheckboxChange}
            name="securitySystem"
          />
        }
        label="Security System"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.updatedKitchen}
            onChange={handleCheckboxChange}
            name="updatedKitchen"
          />
        }
        label="Updated Kitchen"
      />
    </>
  );

  const renderFeaturesFields = () => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.pool}
              onChange={handleCheckboxChange}
              name="pool"
            />
          }
          label="Pool"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.shed}
              onChange={handleCheckboxChange}
              name="shed"
            />
          }
          label="Shed"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.highCeilings}
              onChange={handleCheckboxChange}
              name="highCeilings"
            />
          }
          label="High Ceilings"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.mudRoom}
              onChange={handleCheckboxChange}
              name="mudRoom"
            />
          }
          label="Mud Room"
        />
      </Grid>
      {/* Add more features in similar Grid items here */}
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.fireplace}
              onChange={handleCheckboxChange}
              name="fireplace"
            />
          }
          label="Fireplace"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.hardwoodFloors}
              onChange={handleCheckboxChange}
              name="hardwoodFloors"
            />
          }
          label="Hardwood Floors"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.centralAir}
              onChange={handleCheckboxChange}
              name="centralAir"
            />
          }
          label="Central Air Conditioning"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.solarPanels}
              onChange={handleCheckboxChange}
              name="solarPanels"
            />
          }
          label="Solar Panels"
        />
      </Grid>
    </Grid>
  );

  const renderLayoutFields = () => (
    <>
      <FormControl fullWidth>
        <InputLabel>Floors</InputLabel>
        <Select
          name="floors"
          value={formData.floors}
          onChange={handleSelectChange}
        >
          <MenuItem value="1">1 Floor</MenuItem>
          <MenuItem value="2">2 Floors</MenuItem>
          <MenuItem value="3">3 Floors</MenuItem>
          <MenuItem value="4+">4+ Floors</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.basement}
            onChange={handleCheckboxChange}
            name="basement"
          />
        }
        label="Basement"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.garage}
            onChange={handleCheckboxChange}
            name="garage"
          />
        }
        label="Garage"
      />

      {formData.garage && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Garage Spots</InputLabel>
          <Select
            name="garageSpots"
            value={formData.garageSpots}
            onChange={handleSelectChange}
          >
            <MenuItem value="1">1 Spot</MenuItem>
            <MenuItem value="2">2 Spots</MenuItem>
            <MenuItem value="3">3 Spots</MenuItem>
            <MenuItem value="4+">4+ Spots</MenuItem>
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Bathrooms</InputLabel>
        <Select
          name="bathrooms"
          value={formData.bathrooms}
          onChange={handleSelectChange}
        >
          {/* Bathrooms options up to 6+ */}
          <MenuItem value="1">1 Bathroom</MenuItem>
          <MenuItem value="2">2 Bathrooms</MenuItem>
          <MenuItem value="3">3 Bathrooms</MenuItem>
          <MenuItem value="4">4 Bathrooms</MenuItem>
          <MenuItem value="5">5 Bathrooms</MenuItem>
          <MenuItem value="6+">6+ Bathrooms</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Square Footage"
        name="squareFootage"
        variant="outlined"
        value={formData.squareFootage}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />

      <TextField
        label="Lot Size"
        name="lotSize"
        variant="outlined"
        value={formData.lotSize}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
    </>
  );

  const renderHomeLocationFields = () => (
    <>
      <TextField
        label="Address"
        name="address"
        variant="outlined"
        value={formData.address}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="City"
        name="city"
        variant="outlined"
        value={formData.city}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="State"
        name="state"
        variant="outlined"
        value={formData.state}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Zip Code"
        name="zipCode"
        variant="outlined"
        value={formData.zipCode}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
    </>
  );

  const renderContactFields = () => (
    <>
      <TextField
        label="First Name"
        name="firstName"
        variant="outlined"
        value={formData.firstName}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Last Name"
        name="lastName"
        variant="outlined"
        value={formData.lastName}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Email"
        name="email"
        variant="outlined"
        value={formData.email}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        variant="outlined"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        margin="normal"
        fullWidth
      />
    </>
  );

  const renderStepContent = (step: number) => {
    // Render inputs based on the active step
    // Implement the form fields for each category
    switch (step) {
      case 0:
        return renderHomeLocationFields();
      case 1:
        return renderContactFields();
      case 2:
        return renderDetailsFields();
      case 3:
        return renderLayoutFields();
      case 4:
        return renderFeaturesFields();
      case 5:
        return renderPhotosFields();
      case 6:
        return renderVideoFields();
      case 7:
        return renderDescriptionFields();
      case 8:
        return renderAskingPriceFields();
      default:
        return <div>Great Work!</div>;
    }
  };

  return (
    <StyledCard>
      <CategoryList>
        <Stepper activeStep={activeStep} orientation="vertical">
          {categories.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </CategoryList>
      <FormFields>
        <CardContent>
          <Typography variant="h6">{categories[activeStep]}</Typography>
          {renderStepContent(activeStep)}
        </CardContent>
        <ButtonContainer>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={
              activeStep === categories.length - 1 ? handleSubmit : handleNext
            }
          >
            {activeStep === categories.length - 1 ? "Finish" : "Next"}
          </Button>
        </ButtonContainer>
      </FormFields>
    </StyledCard>
  );
};

export default SellersForm;
