// MapContainer.js
import React from "react";
import GoogleMapReact from "google-map-react";

type Home = {
  lat: number;
  lng: number;
  address: string; // or any other identifier you want to display
  // Add other relevant fields from your homes data
};

type MapContainerProps = {
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  homes?: Home[]; // Array of homes
};

type MarkerProps = {
  lat: number;
  lng: number;
  text: string;
};

const Marker: React.FC<MarkerProps> = ({ text }) => <div>{text}</div>;

const MapContainer: React.FC<MapContainerProps> = ({
  center = { lat: 39.22, lng: -76.58 },
  zoom = 12,
  homes = [],
}) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCBxFttxvInvMZVnsmWhJCJQ2Y911zN8io" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {homes.map((home, index) => (
          <Marker
            key={index}
            lat={home.lat}
            lng={home.lng}
            text={home.address} // Use any identifier you prefer
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default MapContainer;
