// src/pages/login/Login.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { GoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../api/userApi";
import { useUser } from "../../context/UserContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px; // Adjust as needed
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007882; // Adjust to match your theme
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #005f5f;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useUser();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      if (response.message === "Login successful") {
        login(response.user);
        navigate("/");
      } else {
        // Handle login error
      }
    } catch (error) {
      console.error("Login error", error);
    }
  };

  const handleGoogleSuccess = async (tokenResponse: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}?url=googleSignIn`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokenResponse.credential }),
      });
      const data = await response.json();
      if (data && data.user) {
        login(data.user); // Assuming login is a context method to set user session
        navigate("/"); // Navigate to home page or dashboard
      } else {
        // Handle any error or case where user data is not returned
        setErrorMessage("Failed to log in with Google. Please try again.");
      }
    } catch (error) {
      console.error("Login with Google error", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };
  const handleGoogleFailure = (error: any) => {
    // Handle Google login failure
    console.error("Google login failure!", error);
  };

  return (
    <LoginContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <LoginForm onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit">Login</Button>
      </LoginForm>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        // @ts-expect-error possible typing error
        onError={handleGoogleFailure}
      />
      <p>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p>
    </LoginContainer>
  );
};

export default Login;
