// src/pages/viewHome/ViewHome.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navigationbar/Navbar";
import { getHome } from "../../api/homeApi";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HomeIcon from "@mui/icons-material/Home";
import BuildIcon from "@mui/icons-material/Build";
import StraightenIcon from "@mui/icons-material/Straighten";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  padding: 16,
  margin: "16px 0",
  "&:first-of-type": {
    marginTop: 0,
  },
});

const HomeDetailsCard = styled(Card)({
  marginTop: 20,
});

const Media = styled(CardMedia)({
  height: 400,
});

const StatusChip = styled(Chip)({
  margin: "8px 0",
});

const HomeHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const HomeInfo = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "16px",
  alignItems: "center",
  marginTop: "16px",
});

const HomeInfoItem = styled(Box)({
  textAlign: "center",
});

const DetailIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "8px",
});

const ViewHome = () => {
  const { homeId } = useParams<{ homeId: string }>();
  const [home, setHome] = useState<any>(null);

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const response = await getHome(homeId || "");
        setHome(response.data);
      } catch (error) {
        console.error("Error fetching home details:", error);
      }
    };
    fetchHome();
  }, [homeId]);

  if (!home) {
    return <div>Loading...</div>;
  }

  const calculateDaysOnMarket = (createdAt: any) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiff = Number(currentDate) - Number(createdDate);
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  };
  const daysOnMarket = calculateDaysOnMarket(home.created_at);
  const pricePerSqFt = (home.price / home.square_footage).toFixed(2);

  return (
    <>
      <Navbar />
      <Container>
        <HomeDetailsCard>
          <Media
            image={process.env.PUBLIC_URL + "/" + home.default_image}
            title="Home Image"
          />
        </HomeDetailsCard>

        <StyledPaper elevation={3}>
          <HomeHeader>
            <StatusChip
              label={home.status === "FOR_SALE" ? "FOR SALE" : "SOLD"}
              color="success"
            />
            <Typography variant="h6" component="h2" fontWeight="bold">
              {home.address}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {`${home.city}, ${home.state} ${home.zipcode}`}
            </Typography>
          </HomeHeader>

          <HomeInfo>
            <HomeInfoItem>
              <Typography variant="h6" fontWeight="bold">
                ${Number(home.price).toLocaleString()}
              </Typography>
              <Typography variant="body2">Price</Typography>
            </HomeInfoItem>
            <HomeInfoItem>
              <Typography variant="h6">{home.beds}</Typography>
              <Typography variant="body2">Beds</Typography>
            </HomeInfoItem>
            <HomeInfoItem>
              <Typography variant="h6">{home.baths}</Typography>
              <Typography variant="body2">Baths</Typography>
            </HomeInfoItem>
            <HomeInfoItem>
              <Typography variant="h6">
                {home.square_footage.toLocaleString()}
              </Typography>
              <Typography variant="body2">Sq Ft</Typography>
            </HomeInfoItem>
          </HomeInfo>

          <Divider sx={{ marginY: 2 }} />

          <Typography variant="h6" gutterBottom>
            About this home
          </Typography>
          <Typography variant="body1" paragraph>
            {home.description || "No description provided."}
          </Typography>
          <Button variant="text">Show more</Button>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6} sm={3}>
              <DetailIcon>
                <AccessTimeIcon />
                <Typography variant="body1">{daysOnMarket}</Typography>
              </DetailIcon>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DetailIcon>
                <HomeIcon />
                <Typography variant="body1">{home.home_type}</Typography>
              </DetailIcon>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DetailIcon>
                <BuildIcon />
                <Typography variant="body1">{home.year_built}</Typography>
              </DetailIcon>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DetailIcon>
                <StraightenIcon />
                <Typography variant="body1">
                  ${pricePerSqFt} per sq ft
                </Typography>
              </DetailIcon>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DetailIcon>
                <MonetizationOnIcon />
                <Typography variant="body1">${home.hoa_fee} HOA fee</Typography>
              </DetailIcon>
            </Grid>
          </Grid>
        </StyledPaper>
      </Container>
    </>
  );
};

export default ViewHome;
