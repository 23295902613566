import React from "react";
import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

const MenuIcon = () => {
  return (
    <IconButton edge="end" color="primary" aria-label="menu">
      <Menu />
    </IconButton>
  );
};

export default MenuIcon;
