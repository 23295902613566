import React from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import MessageColumn from "../../components/Dashboard/MessageColumn";
import StatusColumn from "../../components/Dashboard/StatusColumn";
import DashboardHeader from "../../components/Dashboard/DashboardContent/DashboardHeader";
import HomeColumn from "../../components/Dashboard/HomeColumn";
import DetailsColumn from "../../components/Dashboard/DetailsColumn";

const DashboardContainer = styled.div`
  display: flex;
  width: calc(100%); // Adjust for padding
  height: calc(
    100vh - 65px
  ); // Adjust to not exceed the window height, assuming Navbar is 65px high
  padding: 0 20px; // Corrected property name for horizontal padding
  box-sizing: border-box;
  overflow: hidden; // Prevent overflow outside this container
  background-color: #eeebff;
`;

const Column = styled.div`
  flex: 1;
  background-color: #eeebff;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto; // Allow scroll within columns
  box-sizing: border-box;

  margin-top: 30px; // Add this line
`;

const LeftColumn = styled(Column)`
  background-color: transparent;
  width: 313px; // Add this line
`;

const CenterColumn = styled(Column)`
  flex: 1.8;
  background-color: #eeebff;
`;

const RightColumn = styled(Column)`
  max-width: 340px; // Add this line
  background-color: transparent;
  padding: 0;
  display: flex;
`;

const DashboardPage = () => {
  return (
    <div style={{ backgroundColor: "#eeebff" }}>
      <Navbar />
      <DashboardContainer>
        <LeftColumn>
          <HomeColumn />
          <MessageColumn />
        </LeftColumn>
        <CenterColumn data-testid="centerColumn">
          <DashboardHeader />
        </CenterColumn>
        <RightColumn>
          <StatusColumn />
          <DetailsColumn />
        </RightColumn>
      </DashboardContainer>
    </div>
  );
};

export default DashboardPage;
