// src/pages/sell/Sell.tsx
import React from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

interface SellingOptionProps {
  title: string;
  reasons: string[];
  price: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const SellPageContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const HeroSection = styled.section`
  background-color: #007882; // Adjust as per your theme
  color: white;
  padding: 40px 20px;
  text-align: center;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  width: 300px;
  text-align: left;
`;

const CardPriceSection = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
`;

const SellingOption: React.FC<SellingOptionProps> = ({
  title,
  reasons,
  price,
  buttonText,
  onButtonClick,
}) => (
  <StyledCard>
    <CardHeader title={title} />
    <CardContent>
      {reasons.map((reason, index) => (
        <Typography key={index} variant="body1">
          <CheckCircleOutlineIcon /> {reason}
        </Typography>
      ))}
    </CardContent>
    <CardPriceSection>
      <Typography variant="h6">Price: {price}</Typography>
    </CardPriceSection>
    <StyledButton variant="contained" color="primary" onClick={onButtonClick}>
      {buttonText}
    </StyledButton>
  </StyledCard>
);

const SellPage = () => {
  const navigate = useNavigate();

  const navigateToCreateListing = () => {
    navigate("/create-listing"); // Update the path as needed
  };
  return (
    <div>
      <Navbar />
      <SellPageContainer>
        <HeroSection>
          <h1>Sell Your Home</h1>
          <p>Choose the best way to sell your home</p>
        </HeroSection>
        <CardsContainer>
          <SellingOption
            title="Do It Yourself"
            reasons={["Full control over the sale", "Save on commission"]}
            price="$0"
            buttonText="List Your Home"
            onButtonClick={navigateToCreateListing}
          />
          <SellingOption
            title="Closing Assistance"
            reasons={["Expert guidance", "Negotiation support"]}
            price="$500"
          />
          <SellingOption
            title="Concierge Service"
            reasons={["Full service", "Stress-free experience"]}
            price="$1000"
          />
        </CardsContainer>
      </SellPageContainer>
    </div>
  );
};

export default SellPage;
