import React from "react";
import { Select, MenuItem, Button, Box } from "@mui/material";
import styled from "styled-components";

const FilterBarContainer = styled(Box)`
  display: flex;
  height: 40px;
  gap: 8px;
  margin: 10px 0;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  border: 1px solid #e0e0e0; // Match the border with the container
  &:hover {
    border: 1px solid #a0a0a0; // Slightly darker border on hover
  }
`;

const StyledSelect = styled(Select)`
  border: 1px solid #e0e0e0; // Match the border with the container
  &:before {
    border-bottom: 1px solid #e0e0e0; // Border for the bottom line of the select
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #a0a0a0; // Slightly darker border on hover
  }
`;

const SaveSearchButton = styled(Button)`
  background-color: teal; // Use your brand color here
  color: white;
  &:hover {
    background-color: darkteal; // A darker shade for the hover state
  }
`;

const FilterBar = () => {
  return (
    <FilterBarContainer>
      <StyledSelect defaultValue="" displayEmpty>
        <MenuItem value="">Any Price</MenuItem>
        {/* ... other MenuItems for prices */}
      </StyledSelect>

      <StyledSelect defaultValue="" displayEmpty>
        <MenuItem value="">All Beds</MenuItem>
        {/* ... other MenuItems for beds */}
      </StyledSelect>

      <StyledSelect defaultValue="" displayEmpty>
        <MenuItem value="">All Baths</MenuItem>
        {/* ... other MenuItems for baths */}
      </StyledSelect>

      <StyledSelect defaultValue="" displayEmpty>
        <MenuItem value="">All Home Types</MenuItem>
        {/* ... other MenuItems for home types */}
      </StyledSelect>

      <StyledButton variant="outlined">More</StyledButton>

      <SaveSearchButton variant="contained">Save Search</SaveSearchButton>
    </FilterBarContainer>
  );
};

export default FilterBar;
