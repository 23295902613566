// src/pages/buy/Buy.tsx.tsx
import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MapContainer from "../../components/mapContainer/MapContainer";
import FilterBar from "../../components/filterBar/filterBar";
import { useSearch } from "../../context/SearchContext";
import { searchHomes } from "../../api"; // Adjust the path as necessary
import HomeListContainer from "../../components/homeListContainer/HomeListContainer";

const navBarHeight = 64; // Actual height of the Navbar in pixels
const filterBarHeight = 40; // Set the actual height of your FilterBar

const MainSection = styled.div`
  display: flex;
  height: calc(100vh - ${navBarHeight + filterBarHeight}px);
`;

const MapSection = styled.div<{ isFullWidth: boolean }>`
  flex-grow: ${({ isFullWidth }) =>
    isFullWidth ? 3 : 1}; // Adjusts size based on isFullWidth
  transition: flex-grow 0.3s ease;
  overflow: hidden;
`;

const HomeListSection = styled.div`
  flex-grow: 1; // Adjusts automatically based on isFullWidth
  overflow-y: auto; // for scrollable list
  padding: 20px;
  background-color: white; // Or any other color
`;

const ToggleButton = styled(IconButton)`
  position: absolute;
  top: ${navBarHeight + filterBarHeight + 10}px; // Adjust top offset
  left: 10px;
  z-index: 10;
  background-color: white !important;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #f0f0f0;
  }
  color: inherit;
  opacity: 1;
`;

const Buy = () => {
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [homes, setHomes] = useState([]); // State to store fetched homes
  const { searchParams } = useSearch();
  const toggleMapSize = () => setIsFullWidth(!isFullWidth);

  useEffect(() => {
    const fetchHomes = async () => {
      if (searchParams.city || searchParams.zipCode || searchParams.address) {
        try {
          const response = await searchHomes(searchParams);
          setHomes(response.data); // Update state with fetched homes
          console.log(response.data); // Temporary log to see the fetched data
        } catch (error) {
          console.error("Error fetching homes:", error);
        }
      } else {
        try {
          const response = await searchHomes(searchParams);
          setHomes(response.data); // Update state with fetched homes
          console.log(response.data); // Temporary log to see the fetched data
        } catch (error) {
          console.error("Error fetching homes:", error);
        }
      }
    };

    fetchHomes();
  }, [searchParams]);

  return (
    <>
      <Navbar />
      <FilterBar />
      <MainSection>
        <HomeListSection>
          <HomeListContainer homes={homes} />
        </HomeListSection>
        <MapSection isFullWidth={isFullWidth}>
          <ToggleButton onClick={toggleMapSize} color="primary">
            {isFullWidth ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </ToggleButton>
          <MapContainer homes={homes} /> {/* Pass homes data to MapContainer */}
        </MapSection>
      </MainSection>
    </>
  );
};

export default Buy;
